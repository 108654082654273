.main {
  background-color: var(--main-color);
}

.title {
  color: var(--secondary-color);
}

.red {
  background-color: red;
  color: green
}

.progress.progress-info {
  background-color: var(--neutro-gris-10);
}

.card-item:hover {
  background-color: lightgray;
}

.sim-card {
  position: relative;
  width: 4.25rem;
  height: 3.125rem;
  margin-top: 1rem;
  border: 0.0625rem solid #000;
  border-radius: 0.25rem;
}

.sim-card:before {
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 2.63rem;
  left: -0.54rem;
  transform: rotate(46deg);
  content: "";
  background-color: #fff;
  border-top: 0.0625rem solid #868686;
}
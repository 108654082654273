.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(1%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-1%);
  }
}

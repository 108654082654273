.main {
  background-color: var(--main-color);
}

.progress.progress-info {
  background-color: var(--neutro-gris-10);
}

.card-item:hover {
  background-color: lightgray;
}



.info-card {
  background: linear-gradient(104.56deg, rgba(255, 255, 255, 0.9) 2.75%, rgba(255, 255, 255, 0.4) 100%);
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
}
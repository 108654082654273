/* @media (min-height: 400px) {
  #items-list {
    max-height: 142px;
    overflow: scroll;
  }
}

@media (min-height: 700px) {
  #items-list {
    max-height: 220px;
    overflow: auto;
  }
} */
#items-list::-webkit-scrollbar {
  display: none;
}

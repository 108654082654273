.scrollbar::-webkit-scrollbar {
  width: 5px; /* Ancho de la barra de desplazamiento */
}

.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color del fondo de la barra de desplazamiento */
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Color del thumb (la parte desplazable) de la barra de desplazamiento */
  border-radius: 5px; /* Borde redondeado para el thumb */
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color del thumb al pasar el cursor sobre él */
}

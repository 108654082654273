.h-btn {
  /* animation: scale-up-center var(0, .25s) ease-out; */
  animation: scale-up 250ms cubic-bezier(0.34, 1.56, 0.64, 1) 0s 1 normal forwards;
}

@-webkit-keyframes scale-up {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(1);
  }
}

@keyframes scale-up {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(1);
  }
}


@keyframes load {
  100% {transform: rotate(360deg);}
}

.loader {
  width: 100px;
  height: 100px;
  border: 10px solid theme('colors.neutral-gray-50');
  border-top: 10px solid theme('colors.primary');
  border-right: 10px solid theme('colors.neutral-gray-50');
  border-radius: 60px;
  animation: load 1s linear infinite;
}

@keyframes l1 {
  25% {transform: rotatex(0deg)}
  100% {transform: rotatex(0deg)}
}
.App {
  text-align: center;
}

/* .root {
    width: 100vh;
} */
/* .main-container {
  background-color: var(--main-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--secondary-color);
} */

